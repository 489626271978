<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addPostcode" ref="form">
        <v-card>
          <v-card-title>Add Postcode</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    hide-details="auto"
                    label="Postcode *"
                    v-model="form.code"
                    :error-messages="form.$getError('code')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import Postcode from '@/models/Postcode'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form({
        code: null
      }),
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    async addPostcode() {
      this.form.$busy = true
      this.form.$clearErrors()

      new Postcode(this.form.$data())
        .save()
        .then(res => {
          this.closeDialog()
          this.showSnackbar('New postcode has been whitelisted', 'success')
          this.$emit('refreshList')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.$clearErrors()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>
