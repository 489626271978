<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Whitelisted Postcodes ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn color="primary" class="mb-2" @click="addPostcodeDialog = true">
        <v-icon left> {{ icons.add }} </v-icon>Add Postcode
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Search by Name or Label"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="postcodes"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.deleted_at`]="{ item }">
        <v-chip class="text-capitalize" :color="getColor(item.status)" dark>
          {{ item.status ? 'Whitelisted' : 'Blacklisted' }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.addedDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          x-small
          depressed
          v-if="item.deleted_at"
          fab
          @click="whiteListPostCode(item.id)"
        >
          <v-icon color="green">
            {{ icons.check }}
          </v-icon>
        </v-btn>

        <v-btn x-small depressed v-else fab @click="blackListPostCode(item.id)">
          <v-icon color="red">
            {{ icons.close }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <AddPostcodeDialog
      v-model="addPostcodeDialog"
      @refreshList="getDataFromApi"
    />
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import { mdiCheck, mdiClose, mdiPlus } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import AddPostcodeDialog from './components/AddPostcodeDialog.vue'

export default {
  name: 'PlansPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    AddPostcodeDialog
  },
  data() {
    return {
      addPostcodeDialog: false,
      icons: {
        add: mdiPlus,
        check: mdiCheck,
        close: mdiClose
      },
      loading: false,
      options: {},
      headers: [
        {
          text: 'Code',
          sortable: true,
          value: 'code'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'deleted_at'
        },
        {
          text: 'Date Created',
          sortable: true,
          value: 'created_at',
          width: '200px'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '150px'
        }
      ],
      search: null,
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },
  computed: {
    ...mapState({
      postcodes: state => state.postcode.list,
      listMeta: state => state.postcode.listMeta
    })
  },
  methods: {
    ...mapActions({
      getPostCodes: 'postcode/getPostCodes',
      deletePostCode: 'postcode/deletePostCode',
      restorePostCode: 'postcode/restorePostCode'
    }),

    ...mapMutations({
      clearPostCodes: 'postcode/clearPostCodeList'
    }),

    async getDataFromApi() {
      this.loading = true
      this.clearPostCodes()
      await this.getPostCodes({
        search: this.search,
        ...this.options
      })
      this.loading = false
    },

    doFilter: debounce(function() {
      this.getDataFromApi()
    }, 600),

    async blackListPostCode(id) {
      this.deletePostCode(id)
        .then(_ => {
          this.showSnackbar('Postcode successfully been blacklisted', 'success')
        })
        .catch(_ => {
          this.showSnackbar(
            'Something went wrong when blacklisting postcode',
            'danger'
          )
        })
        .finally(_ => {
          this.getDataFromApi()
        })
    },

    async whiteListPostCode(id) {
      this.restorePostCode(id)
        .then(_ => {
          this.showSnackbar('Postcode successfully been whitelisted', 'success')
        })
        .catch(_ => {
          this.showSnackbar(
            'Something went wrong when whitelisting postcode',
            'danger'
          )
        })
        .finally(_ => {
          this.getDataFromApi()
        })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    getColor(status) {
      if (status) return 'green'

      return 'red'
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
